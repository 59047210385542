<template>
  <div v-if="sf != null && sf.sform != null && device_item != null">
    <div class="printpageforfiche">
      <div class="panelitem">
        <div class="paneltitle">Kayıt Bilgileri</div>
        <ul class="list-group">
          <li class="list-group-item text-center">
            <img :src="device_item.barcode_url" />
            <div class="text-bold">{{ device_item.device_serial_number }}</div>
          </li>
          <li class="list-group-item">
            <div class="k">Servis Tarihi</div>
            <div class="v">
              {{ moment(sf.sform.sf_date).format("DD.MM.YYYY") }}
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="k">Müşteri</div>
                <div class="v">
                  <div>
                    {{ sf.customer.title }} ({{ sf.customer.customer_code }})
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="k">GSM No</div>
            <div class="v">{{ sf.customer.gsm_primary }}</div>
          </li>
          <li class="list-group-item">
            <div class="k">Marka Model / No</div>
            <div class="v">
              {{ device_item.device_name }} / {{ device_item.device_id }}
            </div>
          </li>
          <li class="list-group-item">
            <div class="k">Seri No</div>
            <div class="v">{{ device_item.device_serial_number }}</div>
          </li>
          <li class="list-group-item">
            <div class="k">Teslimat Adresi</div>
            <div
              class="v"
              v-html="getDeviceTakeDeliveryAddress(device_item)"
            ></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {},

  data() {
    return {
      datasource: {
        qp: { uniq: "", device_id: "" },
      },
    };
  },
  created() {
    try {
      this.datasource.qp.uniq = this.$route.query.uniq;
      this.datasource.qp.device_id = this.$route.query.device_id;
      this.sf.sform.sf_uniq = this.datasource.qp.uniq;
      this.getServiceDetails();
    } catch (e) {
      utils.logError(e);
    }
  },
  methods: {
    ...mapActions(["getServiceDetails"]),

    getDeviceTakeDeliveryAddress(device_item) {
      try {
        let result = "";
        if (this.sf == null || this.sf.sform == null) return result;
        let takeAddress = device_item.delivery_items.find(
          (q) => q.type == "take"
        );
        if (takeAddress) {
          result =
            takeAddress.delivery_address +
            "<br />" +
            takeAddress.district_text +
            "/" +
            takeAddress.city_text;
        }
        return result;
      } catch (e) {
        utils.logError(e);
      }
    },
    moment(v)
    {
      return utils.moment(v);
    }
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
    device_item() {
      let result = this.sf.devices;
      if (this.datasource.qp.device_id != null) {
        result = result.find(
          (q) => q.device_id == this.datasource.qp.device_id
        );
      }

      return result;
    },
  },
  watch: {
    "sf.sform": function (e, v) {
       setTimeout(()=>{    window.print(); },1000)
    },
  },
};
</script>
<style lang="scss" scoped>
@media print {
  html,
  body {
    width: 72mm;
    background: #fff;
    height: auto !important;
  }
}
</style> 